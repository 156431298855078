<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="铸造人ucid">
        <el-input v-model="form.creator_ucid" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="铸造人昵称">
        <el-input v-model="form.creator_name" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="铸造者手机">
        <el-input v-model="form.creator_phone" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="铸造者邮箱">
        <el-input v-model="form.creator_email" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="NFT合集名称">
        <el-input v-model="form.nft_work_name" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="TXID">
        <el-input v-model="form.txid" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="上架最早日期">
        <el-date-picker
          type="date"
          placeholder="请选择日期"
          v-model="form.nft_sale_start"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />
      </el-form-item>
      <el-form-item label="上架最晚日期">
        <el-date-picker
          type="date"
          placeholder="请选择日期"
          v-model="form.nft_sale_end"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />
      </el-form-item>
      <el-form-item label="NFT最小总数">
        <el-input
          type="number"
          v-model="form.min_nft_num"
          placeholder="请输入"
          :class="isMinNFTError ? 'input-error' : ''"
        />
      </el-form-item>
      <el-form-item label="NFT最大总数">
        <el-input
          type="number"
          v-model="form.max_nft_num"
          placeholder="请输入"
          :class="isMaxNFTError ? 'input-error' : ''"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe @sort-change="handleSortChange">
        <el-table-column label="NFT合集名称" prop="name"></el-table-column>
        <el-table-column label="预览图" prop="cover">
          <template slot-scope="scope">
            <el-image
              :src="`${scope.row.cover}?x-oss-process=image/resize,w_100`"
              fit="fill"
              :preview-src-list="[scope.row.cover]"
              style="max-width: 100px; max-height: 100px; margin: 0 auto;"
            />
          </template>
        </el-table-column>
        <el-table-column label="铸造者信息" prop="ucid">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.ucid }}</span>
              <span>{{ scope.row.creator }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="txid" prop="txid">
          <template slot-scope="scope">
            <CopyTxid :txid="scope.row.txid" />
          </template>
        </el-table-column>
        <el-table-column sortable="custom" label="NFT总数" prop="count"></el-table-column>
        <el-table-column sortable="custom" label="总交易CNY" prop="cny"></el-table-column>
        <!-- TODO 隐藏部分 -->
        <!-- <el-table-column sortable="custom" label="总交易BSV" prop="bsv"></el-table-column> -->
        <el-table-column sortable="custom" label="交易笔数" prop="tran_num"></el-table-column>
        <el-table-column sortable="custom" label="人均持有比" prop="own_percent"></el-table-column>
        <el-table-column sortable="custom" label="首次上架日期" prop="first_shell_date">
          <template slot-scope="scope">
            <div>{{ scope.row.first_shell_date | formatDateToDay }}</div>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" label="最后交易时间" prop="late_trans_time">
          <template slot-scope="scope">
            <div>{{ scope.row.late_trans_time | formatDate }}</div>
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="最后交易CNY"
          prop="late_trans_cny"
        ></el-table-column>
        <!-- TODO 隐藏部分 -->
        <!-- <el-table-column
          sortable="custom"
          label="最后交易BSV"
          prop="late_trans_bsv"
        ></el-table-column> -->
        <!-- <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :width="col.width"
          :sortable="col.sortable"
          :formatter="col.formatter"
        /> -->
      </el-table>
      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getSellWorksStatistics } from "@/api/nft.js";
import CopyTxid from "@/components/CopyTxid.vue";
import dayjs from "dayjs";

const TotalSecondsOneDay = 24 * 60 * 60;

export default {
  components: {
    CopyTxid,
  },
  data() {
    return {
      form: {},
      list: [],
      total: 0,
      pageSize: 20,
      page: 1,
      sortBy: "",
      sortOrder: "",
    };
  },
  filters: {
    formatDateToDay: function(value) {
      return dayjs(+value * 1000).format("YYYY-MM-DD");
    },
    formatDate: function(value) {
      return dayjs(+value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {
    params() {
      const { min_nft_num, max_nft_num, nft_sale_start, nft_sale_end } = this.form;

      return {
        page: this.page,
        page_size: this.pageSize,
        creator_ucid: this.form.creator_ucid || undefined,
        creator_name: this.form.creator_name || undefined,
        creator_phone: this.form.creator_phone || undefined,
        creator_email: this.form.creator_email || undefined,
        nft_work_name: this.form.nft_work_name || undefined,
        txid: this.form.txid || undefined,
        nft_sale_start: !!nft_sale_start ? Math.floor(nft_sale_start / 1000) : undefined,
        nft_sale_end: !!nft_sale_end
          ? Math.floor(nft_sale_end / 1000) + TotalSecondsOneDay - 1
          : undefined,
        min_nft_num: !!min_nft_num ? +min_nft_num : undefined,
        max_nft_num: !!max_nft_num ? +max_nft_num : undefined,
        sort_by: this.sortBy || undefined,
        sort_order: this.sortOrder || undefined,
      };
    },
    isMaxNFTError() {
      const maxNum = this.form.max_nft_num;

      if (!maxNum) {
        return false;
      }
      const valid = new RegExp("^[1-9][0-9]*$").test(maxNum);
      return !valid;
    },
    isMinNFTError() {
      const minNum = this.form.min_nft_num;

      if (!minNum) {
        return false;
      }
      const valid = new RegExp("^[1-9][0-9]*$").test(minNum);
      return !valid;
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      if (this.isMaxNFTError || this.isMinNFTError) {
        this.$message.warning("请输入正整数");
        return;
      }

      const maxNum = this.form.max_nft_num;
      const minNum = this.form.min_nft_num;
      if (minNum && maxNum && maxNum < minNum) {
        this.$message.warning("NFT最小总数要小于最大总数");
        return;
      }

      const startDate = this.form.nft_sale_start;
      const endDate = this.form.nft_sale_end;
      if (startDate && endDate && startDate > endDate) {
        this.$message.warning("最早日期要小于最晚日期");
        return;
      }

      try {
        const { data } = await getSellWorksStatistics(this.params);
        const { total = 0, items } = data?.data;
        this.list = [...(items || [])];
        this.total = total;
      } catch (error) {}
    },
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {};
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
  },
};
</script>

<style lang="less">
.input-error {
  input {
    border-color: red !important;
  }
}
</style>
